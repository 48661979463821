import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// import thunk from 'redux-thunk'
import { appReducer } from './store/reducer'
import RootSaga from './saga'

const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ }: any = window;
const composeEnhancers = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'revrex_store_root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, appReducer);

const sagaMiddleware = createSagaMiddleware();
// const store = createStore(appReducer, applyMiddleware(thunk))
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))
const persistor = persistStore(store)

sagaMiddleware.run(RootSaga);

export { store, persistor };