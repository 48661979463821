
import { call, put } from "redux-saga/effects";
import { updateUser, authenticateUser,updateAccessToken } from "../store/user";
import { API_URLS } from "../resources/constants";
import { formQueryString } from '../resources/functions'
import {CallApi} from './callApi'

export function* getData(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.FOUR_THREE_THREE_B.DETAILS + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* create(action: any): any {
    try {
      const payload = action.payload;
      const { branch_id, params} = payload;
      const urls = API_URLS.FOUR_THREE_THREE_B.CREATE + '?month=' + params.month + '&year=' + params.year + `&branch_id=${branch_id}`;
      let result = yield call(CallApi.POST, urls, params, true);
      action && action.callbackSuccess(result);
      
    } catch (e) {
    
      action && action.callbackError(e)
    }
}

export function* update(action: any): any {
    try {
      const payload = action.payload;
      let result = yield call(CallApi.PUT, API_URLS.FOUR_THREE_THREE_B.UPDATE + `/${payload.id}`,payload, true);
      action && action.callbackSuccess(result);
    } catch (e) {
      action && action.callbackError(e)
    }
}

export function* getIncome(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.FOUR_THREE_THREE_B.MONTHLY_EXPENSE + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

