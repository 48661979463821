import React, { useEffect, useRef, useState,ReactNode } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import toast from 'react-hot-toast';
import moment from 'moment';
import { currencyFormat } from '../../../resources/helper';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from "react-datepicker";
import '../../../assets/css/datepicker.css'
import { TransactionAdjustmentFormI } from '../../../resources/form-props';
import { SELECT_CUSTOM_STYLE } from '../../../resources/constants';
import SelectCustomOption from '../../../components/SelectCustomOption';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateTransactionAdjustmentValidate } from '../../../resources/form-validator';

interface CreateTransactionAdjustmentModalProps {
    show: boolean;
    onHide: () => void;
    onSave: (data:any) => void;
    glAccounts: any[];
    filterAllAccountList: (inputValue: string) => Promise<any[]>;
    selectedCalendar : any
}


const CreateTransactionAdjustmentModal: React.FC<CreateTransactionAdjustmentModalProps> = ({
    show, onHide, onSave,glAccounts, filterAllAccountList , selectedCalendar
}) => {
    const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm<TransactionAdjustmentFormI>({
        defaultValues: {
            description: '',
            transaction_at: new Date(selectedCalendar.year,selectedCalendar.month - 1,1),
            adjustment_debit: 0,
            adjustment_credit: 0,
            adjustment_master_debit_account_id: null,
            adjustment_master_credit_account_id: null,
        },
        resolver: yupResolver(CreateTransactionAdjustmentValidate)
    });
 
    
    const calendarRef = useRef<any>(null);
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#d1ffbd'));


    const onSubmit = (data: any) => {
        onSave(data);
        reset();
        onHide();
    };

    const handleClose = () => {
        reset();
        onHide();
    };

    const openCalendar = () => {
        // if (calendarRef.current) {
        //     calendarRef.current?.setOpen(true)
        // }
        const datePickerInput = document.querySelector("#transaction_at");

        if (datePickerInput) {
            (datePickerInput as HTMLElement).focus();
        }
    }

  

    const onDateChange = (date: Date | null) => {
        setValue('transaction_at', date); 
    };


    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Enter Transaction Adjustment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)} className="create_adjustment_modal">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th style={{ width: '200px' }}>Date</th>
                                <th>Description</th>
                                <th>Debit Amount</th>
                                <th>Debit GL Account</th>
                                <th>Credit Amount</th>
                                <th>Credit GL Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-start">
                                    <Form.Group className="col-sm-12" controlId="Date" style={{ position: 'relative' }}>
                                    <Controller
                                        name="transaction_at"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date: Date | null) => {
                                                onDateChange(date);
                                            }}
                                            onBlur={field.onBlur}
                                            ref={field.ref} // Ensure ref is properly forwarded
                                            id="transaction_at"
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={40}
                                            className="form-control"
                                            dateFormat="MM/dd/yyyy"
                                        />
                                        )}
                                    />

                                        <i
                                            className="fa fa-calendar fa-2xl"
                                            aria-hidden="true"
                                            onClick={openCalendar}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                            }}
                                        ></i>
                                    </Form.Group>
                                    {
                                        errors && errors.transaction_at &&  errors.transaction_at?.message && (
                                            <Form.Text className="d-flex text-danger">
                                                <strong>
                                                    {errors.transaction_at?.message as ReactNode || ''}
                                                 </strong>
                                            </Form.Text>
                                        ) || ''
                                    }
                                </td>   
                                <td>
                                    <Form.Group className="col-sm-12" controlId="description">
                                    <Controller
                                        name="description"
                                        control={control} 
                                        render={({ field }) => (
                                            <Form.Control
                                                {...field}
                                                type="text"
                                                placeholder="Enter Description"
                                                className="form-control"
                                            />
                                        )}
                                    />
                                    </Form.Group>
                                    {
                                        errors && errors.description &&  errors.description?.message && (
                                            <Form.Text className="d-flex text-danger">
                                                <strong>
                                                    {errors.description?.message as ReactNode || ''}
                                                 </strong>
                                            </Form.Text>
                                        ) || ''
                                    }
                                </td> 
                                <td>
                                    <Form.Group className="col-sm-12" controlId="adjustment_debit">
                                    <Controller
                                        name="adjustment_debit" 
                                        control={control} 
                                        render={({ field }) => (
                                            <Form.Control
                                                {...field}
                                                type="number"
                                                placeholder="Enter Debit Amount"
                                                className="form-control"
                                                step="0.01" 
                                                onFocus={(e) => e.target.value === '0' && (e.target.value = '')}
                                                onBlur={(e) => e.target.value === '' && (e.target.value = '0')}
                                            />
                                        )}
                                    />
                                    </Form.Group>
                                    {
                                        errors && errors.adjustment_debit &&  errors.adjustment_debit?.message && (
                                            <Form.Text className="d-flex text-danger">
                                                <strong>
                                                    {errors.adjustment_debit?.message as ReactNode || ''}
                                                 </strong>
                                            </Form.Text>
                                        ) || ''
                                    }
                                </td>    
                                <td>
                                    <Form.Group className="col-sm-12" controlId="adjustment_master_debit_account_id">
                                        <Controller
                                            control={control}
                                            name="adjustment_master_debit_account_id"
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <AsyncSelect
                                                    isClearable={true}
                                                    placeholder="Please select GL acc"
                                                    defaultOptions={glAccounts}
                                                    loadOptions={filterAllAccountList}
                                                    onChange={(selectedOption) => {
                                                        onChange(selectedOption);
                                                    }}
                                                    className="vp-dropdown"
                                                    menuPosition="fixed"
                                                    value={value}
                                                    defaultValue={value}
                                                    styles={selectCustomStyle}
                                                    components={{ Option: SelectCustomOption }}
                                                />
                                            )}
                                        />

                                    </Form.Group>
                                    {
                                        errors && errors.adjustment_master_debit_account_id &&  errors.adjustment_master_debit_account_id?.message && (
                                            <Form.Text className="d-flex text-danger">
                                                <strong>
                                                    {errors.adjustment_master_debit_account_id?.message as ReactNode || ''}
                                                 </strong>
                                            </Form.Text>
                                        ) || ''
                                    }
                                </td> 
                                <td>
                                    <Form.Group className="col-sm-12" controlId="adjustment_credit">
                                    <Controller
                                        name="adjustment_credit" 
                                        control={control} 
                                        render={({ field }) => (
                                            <Form.Control
                                                {...field}
                                                type="number"
                                                placeholder="Enter Credit Amount"
                                                className="form-control"
                                                step="0.01" 
                                                onFocus={(e) => e.target.value === '0' && (e.target.value = '')}
                                                onBlur={(e) => e.target.value === '' && (e.target.value = '0')}
                                            />
                                        )}
                                    />
                                    </Form.Group>
                                    {
                                        errors && errors.adjustment_credit &&  errors.adjustment_credit?.message && (
                                            <Form.Text className="d-flex text-danger">
                                                <strong>
                                                    {errors.adjustment_credit?.message as ReactNode || ''}
                                                 </strong>
                                            </Form.Text>
                                        ) || ''
                                    }
                                </td>    
                                <td>
                                <Form.Group className="col-sm-12" controlId="adjustment_master_credit_account_id">
                                        <Controller
                                            control={control}
                                            name="adjustment_master_credit_account_id"
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <AsyncSelect
                                                    isClearable={true}
                                                    placeholder="Please select GL acc"
                                                    defaultOptions={glAccounts}
                                                    loadOptions={filterAllAccountList}
                                                    onChange={(selectedOption) => {
                                                        onChange(selectedOption); 
                                                    }}
                                                    className="vp-dropdown"
                                                    menuPosition="fixed"
                                                    value={value}
                                                    defaultValue={value}
                                                    styles={selectCustomStyle}
                                                    components={{ Option: SelectCustomOption }}
                                                />
                                            )}
                                        />

                                    </Form.Group>
                                    {
                                        errors && errors.adjustment_master_credit_account_id &&  errors.adjustment_master_credit_account_id?.message && (
                                            <Form.Text className="d-flex text-danger">
                                                <strong>
                                                    {errors.adjustment_master_credit_account_id?.message as ReactNode || ''}
                                                 </strong>
                                            </Form.Text>
                                        ) || ''
                                    }
                                    
                                </td>                  
                               
                                   
                            </tr>
                        </tbody>
                    </table>
                    
                   
                    <div className="d-flex justify-content-end mt-3">
                        <Button type="submit" variant="primary" className="me-2">Submit</Button>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateTransactionAdjustmentModal;
