import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from 'react-bootstrap/Alert';
import { URLS } from '../../resources/constants'
import { getUser } from '../../store/user/selectors'
// import { getBankAccountMatchSettings, getAccountSettings } from '../../store/settings/selectors'
// import { getFirstLoginStatus } from '../../store/user/selectors'
import { getAccessToken } from '../../store/user/selectors'
import { useUserApi } from '../../resources/hooks/api/userApiHook'
import { useSettingsApi } from '../../resources/hooks/api/useSettingsApiHook';
import { useReportApi } from '../../resources/hooks/api/useReportApiHook';
//import { useUserDataApi } from '../../resources/hooks/api/userDataApiHook'
import { lastYears } from '../../resources/functions';
import { Link } from 'react-router-dom'
import Select from 'react-select';


import { getBranch } from '../../store/user/selectors'


interface Props {

}

const BusinessRatio: React.FC<Props> = () => {

    const { dashboardRaioData } = useReportApi();
    // const { fetchAccountSettingsData } = useSettingsApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dashboardRatio, setDashboardRatio] = useState<any | null>(null);
    const [searchParams, setSearchParams] = useState<any>({
        year: { value: new Date().getFullYear(), label: '' + new Date().getFullYear() }
    });

    const branchData = useSelector(getBranch)

    useEffect(() => {
        // fetchUserSteps();
        getDashboardRatioData();
    }, [])

    /*const fetchUserSteps = () => {
        setIsLoading(true);
        fetchAccountSettingsData({ branch_id: +branchData['id'] }, (message: string, resp: any) => {
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);

        })
    }*/

    const getDashboardRatioData = () => {
        setIsLoading(true);
        dashboardRaioData({ year: searchParams.year.value, branch_id: +branchData['id'] }, (msg: string, resp: any) => {
            setIsLoading(false)
            setDashboardRatio(resp.data.data);
        }, (msg: string, resp: any) => {
            setIsLoading(false)
        })
    }

    const _handleChangeSearchParam = (e: any, type: string) => {
        if (type === 'year') {
            setSearchParams({
                ...searchParams,
                'year': e
            })
        }
    }

    const fetchRatios = () => {
        getDashboardRatioData()
    }
   
    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title py-4">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <h1 className="h3 font-weight-700  mb-0">Business Ratios</h1>
                        </div>
                    </div>
                </div>

                <div className="filter-box-top">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="filter-wrap">
                                <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'year')}
                                        options={lastYears(5)}
                                        defaultValue={searchParams['year']}
                                        className={'zindex-10'}
                                    />
                                </div>

                                <div className="filter-box">
                                    <button type="button" onClick={fetchRatios}   className="btn-primary btn btn-lg">Fetch Ratios &nbsp; <i className="fa fa-search" aria-hidden="true"></i></button>
                                </div>
                                
                                
                                <div className="filter-btn">
                                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="filter-btn">
                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                        <div className="loader"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">

                    {
                       
                            <div className="row mt-4">
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-gmr shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-gmr text-uppercase mb-1">
                                                        <strong>Gross Margin Ratio</strong>
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{(dashboardRatio?.gross_margin_ratio || 0).toFixed(2)}</div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-roar shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-roar text-uppercase mb-1">
                                                        <strong>Return On Assets Ratio</strong>
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                        {(dashboardRatio?.return_on_assets_ratio || 0).toFixed(2)}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-roer shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-roer text-uppercase mb-1">
                                                        <strong>Return On Equity Ratio</strong>
                                                    </div>
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col-auto">
                                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                                                {(dashboardRatio?.return_on_equity_ratio || 0).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-cr shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-cr text-uppercase mb-1">
                                                        <strong>Current Ratio</strong>
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                        {(dashboardRatio?.cash_ratio || 0).toFixed(2)}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-atr shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-atr text-uppercase mb-1">
                                                        <strong>Acid-Test Ratio</strong>
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                        {(dashboardRatio?.acid_test_ratio || 0).toFixed(2)}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-cashr shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-cashr text-uppercase mb-1">
                                                        <strong>Cash Ratio</strong>
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                        {(dashboardRatio?.cash_ratio || 0).toFixed(2)}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-ocfr shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-ocfr text-uppercase mb-1">
                                                        <strong>Operating Cash Flow Ratio</strong>
                                                    </div>
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col-auto">
                                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                                                {(dashboardRatio?.operating_cashflow_ratio || 0).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-dr shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-dr text-uppercase mb-1">
                                                        <strong>Debt Ratio</strong>
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                        {(dashboardRatio?.debt_ratio || 0).toFixed(2)}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-dter shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-dter text-uppercase mb-1">
                                                        <strong>Debt To Equity Ratio</strong>
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                        {(dashboardRatio?.debt_to_equity_ratio || 0).toFixed(2)}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-icr shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-icr text-uppercase mb-1">
                                                        <strong>Interest Coverage Ratio</strong>
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                        {(dashboardRatio?.interest_coverage_ratio || 0).toFixed(2)}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-dscr shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-dscr text-uppercase mb-1">
                                                        <strong>Debt Service Coverage Ratio</strong>
                                                    </div>
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col-auto">
                                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                                                {(dashboardRatio?.debt_service_coverage_ratio || 0).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-aturnr shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-aturnr text-uppercase mb-1">
                                                        <strong>Asset turnover Ratio</strong>
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                        {(dashboardRatio?.asset_turnover_ratio || 0).toFixed(2)}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-itr shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-itr text-uppercase mb-1">
                                                        <strong>Inventory Turnover Ratio</strong>
                                                    </div>
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col-auto">
                                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                                                {(dashboardRatio?.inventory_turnover_ratio || 0).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-dsiir shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-dsiir text-uppercase mb-1">
                                                        <strong>Days Sales In Inventory Ratio</strong>
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                        {(dashboardRatio?.days_sales_in_inventory_ratio || 0).toFixed(2)}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                       
                    }

                </div>
                </div>

        

        </div>
        </div>
    )

}

export default BusinessRatio;