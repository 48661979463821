import { FC, useEffect, useState, useRef, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import toast from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
// import './ChartAccountModal.css'

import { SettingsBudgetValidate } from '../../../../resources/form-validator';
import { MONTHS, BUDGET_BASED } from '../../../../resources/constants';
import { transformMonths } from '../../../../resources/functions';
import closeBtn from '../../../../assets/images/close-btn.svg'
import { SettingsBudgetI } from '../../../../resources/form-props';
import { useSettingsApi } from '../../../../resources/hooks/api/useSettingsApiHook';

import {getBranch} from '../../../../store/user/selectors'

interface Props {
    closeModal: () => void,
    record: any
}

const SettingsAccountingModal: FC<Props> = ({ closeModal, record }) => {

    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [disableCalendarYear, setDisableCalendarYear] = useState<boolean>(false)
    const [months, setMonths] = useState(transformMonths());

    const { updateAccountingSettings } = useSettingsApi();

    const branchData = useSelector(getBranch)

    const { control, register, reset, setValue, getValues, watch, handleSubmit, formState: { errors } } = useForm<SettingsBudgetI>({
        defaultValues: {
            first_month_of_fiscal_year: record && record.first_month_of_fiscal_year || null,
            first_month_of_income_tax_year: record && record.first_month_of_income_tax_year || null,
            budget_based_on: record && record.budget_based_on || BUDGET_BASED[1],
        },
        resolver: yupResolver(SettingsBudgetValidate)
    });
    const budgetBaseedOnUpdate = watch('budget_based_on', '');

    useEffect(() => {
        if ( budgetBaseedOnUpdate && budgetBaseedOnUpdate['value'] == 2 ) {
            setDisableCalendarYear(true);
            setValue('first_month_of_income_tax_year', months[0]);
        } else if ( budgetBaseedOnUpdate && budgetBaseedOnUpdate['value'] == 1 ) {
            setDisableCalendarYear(false);
        }
    }, [budgetBaseedOnUpdate])

    const processData = (data: any) => {
        const params = {
            budget_based_on: data.budget_based_on && data.budget_based_on.value || null,
            first_month_of_fiscal_year: data.first_month_of_fiscal_year && data.first_month_of_fiscal_year.value || null,
            first_month_of_income_tax_year: data.first_month_of_income_tax_year && data.first_month_of_income_tax_year.value || null
        }
        setShowLoader(true);
        updateAccountingSettings({params, branch_id: +branchData['id']}, (message: string, resp: any) => {
            setShowLoader(false);
            toast.success(message);
            _handleCloseModal();
        }, (message: string, resp: any) => {
            setShowLoader(false);
            toast.error(message);
        })
    }

    const _handleCloseModal = () => {
        reset();
        closeModal();
    }

    return (
        <Modal show={true} onHide={_handleCloseModal} backdrop="static" animation={false} size="lg">
            <div className="">
                <div className="">
                    <Form onSubmit={handleSubmit(processData)}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Accounting</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={_handleCloseModal}><img src={closeBtn} alt="" /></button>
                        </div>
                        <div className="modal-body pb-0">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Budget based on<strong className='text-danger'>*</strong></label>
                                        <Controller
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <Select
                                                    placeholder={'Select budget type'}
                                                    onChange={onChange}
                                                    defaultValue={value}
                                                    options={BUDGET_BASED}
                                                />
                                            )}
                                            control={control}
                                            name="budget_based_on"
                                        />
                                        {
                                            errors && errors['budget_based_on'] && errors['budget_based_on'].message && (
                                                <Form.Text className="text-danger">
                                                    {errors['budget_based_on'].message as ReactNode || ''}
                                                </Form.Text>
                                            ) || ''
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">First month of calendar year<strong className='text-danger'>*</strong></label>
                                        <Controller
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <Select
                                                    placeholder={'Select Month'}
                                                    onChange={(e: any) => {
                                                        const param = {
                                                            target: {
                                                                name: 'first_month_of_income_tax_year',
                                                                value: e
                                                            }
                                                        }
                                                        onChange(param)
                                                    }}
                                                    value={value}
                                                    defaultValue={value}
                                                    options={months}
                                                    isDisabled={ disableCalendarYear }
                                                />
                                            )}
                                            control={control}
                                            name="first_month_of_income_tax_year"
                                        />
                                        {
                                            errors && errors['first_month_of_income_tax_year'] && errors['first_month_of_income_tax_year'].message && (
                                                <Form.Text className="text-danger">
                                                    {errors['first_month_of_income_tax_year'].message as ReactNode || ''}
                                                </Form.Text>
                                            ) || ''
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">First month of fiscal year<strong className='text-danger'>*</strong></label>
                                        <Controller
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <Select
                                                    placeholder={'Select Month'}
                                                    onChange={(e: any) => {
                                                        const param = {
                                                            target: {
                                                                name: 'first_month_of_fiscal_year',
                                                                value: e
                                                            }
                                                        }
                                                        onChange(param)
                                                    }}
                                                    value={value}
                                                    defaultValue={value}
                                                    options={months}
                                                />
                                            )}
                                            control={control}
                                            name="first_month_of_fiscal_year"
                                        />
                                        {
                                            errors && errors['first_month_of_fiscal_year'] && errors['first_month_of_fiscal_year'].message && (
                                                <Form.Text className="text-danger">
                                                    {errors['first_month_of_fiscal_year'].message as ReactNode || ''}
                                                </Form.Text>
                                            ) || ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-cancel" onClick={_handleCloseModal}>Close</button>
                            <button type="submit" className="btn btn-secondary">
                                {showLoader && (<Spinner animation="border" size="sm" />) || 'Save changes'}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    )
}

export default SettingsAccountingModal;