import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import Card from 'react-bootstrap/Card';
import DatePicker from "react-datepicker";
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';

import toast from 'react-hot-toast';
import { useChartAccountApi } from '../../resources/hooks/api/chartAccountApiHook';

import AsyncSelect from 'react-select/async';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { getBranch } from '../../store/user/selectors'

import { GL_VP, MONTHS, SELECT_CUSTOM_STYLE, URLS } from '../../resources/constants';
import SelectCustomOption from '../../components/SelectCustomOption';
// import CustomDateInput from '../../components/CustomDateInput';
import { BankReconcileFormValidate } from '../../resources/form-validator';
import { BankReconcileI } from '../../resources/form-props';
import { glClassificationApi } from '../../resources/hooks/api/glClassificationApi';

import '../../assets/css/datepicker.css'

interface Props {

}

const BankReconcile: React.FC<Props> = () => {

    const navigate = useNavigate();
    const branchData = useSelector(getBranch)

    const { glAccountsList } = useChartAccountApi();
    const { glReconcileOpeningBalanceFetch } = glClassificationApi();

    const calendarRef = useRef<any>(null);
    const [selectedFilter, setSelectedFilter] = useState<any>({
        glAccount: null,
        endDate: moment().endOf('month').utc().format()
        // year: null,
        // month: null
    });
    const [isSelecteBank, setIsSelecteBank] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reconcileExists, setReconcileExists] = useState<boolean>(false);
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#fff'));

    const { control, register, watch, handleSubmit, getValues, resetField, reset, setValue, formState: { errors } } = useForm<BankReconcileI>({
        defaultValues: {
            beginningBalance: '0.00',
            endingBalance: '0.00',
            // endDate: new Date()
        },
        resolver: yupResolver(BankReconcileFormValidate)
    });

    const fetchOpenningBalance = () => {
        const params = {
            branch_id: +branchData['id'],
            bank_account_id: selectedFilter['glAccount'].value,
            month: +moment(selectedFilter['endDate']).format('M'),
            year: +moment(selectedFilter['endDate']).format('Y')
        }

        console.log(params)
        setIsLoading(true)
        glReconcileOpeningBalanceFetch(params, (message: string, resp: any) => {
            setIsLoading(false)
            setValue("beginningBalance", (resp.data.data.opening_balance.toFixed(2)))
            // setReconcileExists(resp.data.data.reconciliation_exists)

            if(resp.data.data.reconciliation_exists){

                var queryParams = [];
               
                if (selectedFilter['endDate']) {
                    queryParams.push(`endDate=${moment(selectedFilter['endDate']).format("YYYY-MM-DD")}`)
                }
                const url = URLS.BANK_RECONCILE_TRANSACTIONS.replace(":coa", selectedFilter['glAccount']['account']) + '?' + queryParams.join('&');
                navigate(url)
            }

       
        }, (message: string, resp: any) => {
            setIsLoading(false)
        });
    }

    const openCalendar = () => {
        if (calendarRef.current) {
            calendarRef.current?.setOpen(true)
        }
    }

    const fechAccountList = (searchText: string = ''): Promise<any[]> => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            params['parent_account_in'] = GL_VP.BANK_ACCOUNT.account.join(',');
            params['include_assigned_vp'] = 1;

            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id'],
                    account: e['account']
                }));
                return resolve(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    const filterAccountList = async (inputValue: string) => {
        const data = await fechAccountList(inputValue)
        return data;
    };

    const _handleChangeSearchParam = (e: any, type: string) => {
        if (type === 'endDate') {
            setSelectedFilter({
                ...selectedFilter,
                'endDate': moment(e).endOf('month').utc().format()
            })
        } else if (type === 'glAccount') {
            setSelectedFilter({
                ...selectedFilter,
                'glAccount': e
            })
        }
    }

    const completeFirstStep = () => {
        setIsSelecteBank(true);
        fetchOpenningBalance()
    }

    const processData = (data: any) => {
        var queryParams = [];
        if (data['beginningBalance']) {
            queryParams.push(`beginningBalance=${data['beginningBalance']}`)
        }
        if (data['endingBalance']) {
            queryParams.push(`endingBalance=${data['endingBalance']}`)
        }
        if (selectedFilter['endDate']) {
            queryParams.push(`endDate=${moment(selectedFilter['endDate']).format("YYYY-MM-DD")}`)
        }

        const url = URLS.BANK_RECONCILE_TRANSACTIONS.replace(":coa", selectedFilter['glAccount']['account']) + '?' + queryParams.join('&');
        navigate(url)

    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Bank Reconcile</h1>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="filter-btn">
                            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                <div className="loader"></div>
                            </div>
                        </div>
                    </div>
                    <div className="align-content-center d-flex justify-content-center">
                        <div className="col-md-8">
                            <Card >
                                <Card.Header>Which account do you want to reconcile?</Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Please select bank acc<strong className='text-danger'>*</strong></label>
                                                <AsyncSelect
                                                    isClearable={true}
                                                    placeholder={'Select any'}
                                                    defaultOptions
                                                    loadOptions={filterAccountList}
                                                    onChange={(e: any) => _handleChangeSearchParam(e, 'glAccount')}
                                                    className=''
                                                    menuPosition='fixed'
                                                    styles={selectCustomStyle}
                                                    components={{ Option: SelectCustomOption }}
                                                    isDisabled={isSelecteBank}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group date-picker-container"> 
                                                <label htmlFor="">Ending date<strong className='text-danger'>*</strong></label>
                                                <DatePicker
                                                    ref={calendarRef}
                                                    selected={new Date(selectedFilter['endDate'])}
                                                    onChange={(date: Date) => _handleChangeSearchParam(date, 'endDate')}
                                                    className='form-control'
                                                    dateFormat={'MM/dd/yyyy'}
                                                    maxDate={new Date()}
                                                    showMonthYearPicker
                                                    disabled={isSelecteBank}
                                                    calendarIconClassname='tex-end'
                                                />

                                                <i className="fa fa-calendar fa-2xl cursor-pointer calendar-icon" aria-hidden="true" onClick={openCalendar}></i>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !isSelecteBank && (
                                            <div className="row mt-3">
                                                <div className="col-md-3">
                                                    <button type="button" className="btn btn-primary" onClick={() => completeFirstStep()} disabled={!selectedFilter['glAccount'] || !selectedFilter['endDate']}>Start Reconciling</button>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        isSelecteBank && (
                                            <>
                                                <div className="row mt-5">
                                                    <Card.Title>Add the following information</Card.Title>
                                                </div>
                                                <Form onSubmit={handleSubmit(processData)} className='mt-3'>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label className=''>Beginning balance<strong className='text-danger'>*</strong></label>
                                                                {/* <input {...register('beginningBalance')} placeholder='Beginning balance' className='form-control' /> */}
                                                                <Controller
                                                                    name={`beginningBalance`}
                                                                    control={control}
                                                                    rules={{ required: 'Amount is required', min: { value: 0, message: 'Amount must be positive' } }}
                                                                    render={({ field }) => (
                                                                        <InputGroup hasValidation className="input-group-custom">
                                                                            <InputGroup.Text>$</InputGroup.Text>
                                                                            <CurrencyInput
                                                                                className='form-control'
                                                                                placeholder="0.00"
                                                                                value={field.value}
                                                                                onValueChange={(value) => setValue(`beginningBalance`, value || '')}
                                                                                allowDecimals
                                                                                decimalsLimit={2}
                                                                                decimalSeparator="."
                                                                                groupSeparator=","
                                                                                disableAbbreviations
                                                                                allowNegativeValue={false}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {errors?.beginningBalance?.message}
                                                                            </Form.Control.Feedback>
                                                                        </InputGroup>
                                                                    )}
                                                                />
                                                                {/* <input 
                                                                    {...register('beginningBalance')} 
                                                                    placeholder='Beginning balance' 
                                                                    className='form-control' 
                                                                    onFocus={(e) => e.target.value === '0.00' && (e.target.value = '')}
                                                                    onBlur={(e) => e.target.value === '' && (e.target.value = '0.00')}
                                                                />
                                                                {
                                                                    errors && errors.beginningBalance && errors.beginningBalance.message && (
                                                                        <Form.Text className="text-danger d-flex">
                                                                            <strong>{errors.beginningBalance?.message as ReactNode || ''}</strong>
                                                                        </Form.Text>
                                                                    ) || ''
                                                                } */}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="">Ending balance<strong className='text-danger'>*</strong></label>
                                                                {/* <input {...register('endingBalance')} placeholder='Ending balance' className='form-control' /> */}
                                                                <Controller
                                                                    name={`endingBalance`}
                                                                    control={control}
                                                                    rules={{ required: 'Amount is required', min: { value: 0, message: 'Amount must be positive' } }}
                                                                    render={({ field }) => (
                                                                        <InputGroup hasValidation className="input-group-custom">
                                                                            <InputGroup.Text>$</InputGroup.Text>
                                                                            <CurrencyInput
                                                                                className='form-control'
                                                                                placeholder="0.00"
                                                                                value={field.value}
                                                                                onValueChange={(value) => setValue(`endingBalance`, value || '')}
                                                                                allowDecimals
                                                                                decimalsLimit={2}
                                                                                decimalSeparator="."
                                                                                groupSeparator=","
                                                                                disableAbbreviations
                                                                                allowNegativeValue={false}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {errors?.endingBalance?.message}
                                                                            </Form.Control.Feedback>
                                                                        </InputGroup>
                                                                    )}
                                                                />
                                                                {/* <input 
                                                                    {...register('endingBalance')} 
                                                                    placeholder='Ending balance' 
                                                                    className='form-control' 
                                                                    onFocus={(e) => e.target.value === '0.00' && (e.target.value = '')}
                                                                    onBlur={(e) => e.target.value === '' && (e.target.value = '0.00')}
                                                                />
                                                                {
                                                                    errors && errors.endingBalance && errors.endingBalance.message && (
                                                                        <Form.Text className="text-danger d-flex">
                                                                            <strong>{errors.endingBalance?.message as ReactNode || ''}</strong>
                                                                        </Form.Text>
                                                                    ) || ''
                                                                } */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row justify-content-center d-flex mt-3">
                                                        <div className="col-md-3 justify-content-center d-flex">
                                                            <button type="submit" className="btn btn-primary">Continue</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </>
                                        )
                                    }
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BankReconcile;