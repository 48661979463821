import { RootState } from '../types'

export const getSettings = (state: RootState) => state.settings
export const getAccountSettings = (state: RootState) => state.settings.accouting
export const getPrimarySalesAccountSettings = (state: RootState) => state.settings.primary_sales_account
export const getPrimaryStateTaxAccountSettings = (state: RootState) => state.settings.primary_state_tax_account
export const getBankAccountMatchSettings = (state: RootState) => state.settings.bank_account_match
export const getBudgetSettings = (state: RootState) => state.settings.budget_settings
export const getSystemAccessSettings = (state: RootState) => state.settings.system_access
export const getFirstLoginStatus = (state: RootState) => state.settings.fisrtLoginCheck
