
import { call, put } from "redux-saga/effects";
import { API_URLS } from "../resources/constants";
import { formQueryString } from '../resources/functions'
import {CallApi} from './callApi'

export function* fetchBudget(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.BUDGET.FETCH_DETAILS + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* addBudget(action: any): any {
  try {
    const payload = action.payload;
    const {formData, ...queryString} = payload;
    const url = API_URLS.BUDGET.ADD + '?' + formQueryString(queryString)
    let result = yield call(CallApi.POST, url, formData,true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}