
import * as yup from "yup";

export const LoginFormValidate = yup.object().shape({
    email: yup.string().email('Invalid Email').required('Email  required'),
    password: yup.string().required('Password required')
})

export const CAFormValidate = yup.object().shape({
    revrex_account_id: yup.number().nullable(),
    account: yup.object().shape( {
        label: yup.string().required('Account Number Required'),
        value: yup.string().required('Account Number Required'),
    }).typeError('Account number required').required('Account number required'),
    financial_statement: yup.object().shape( {
        label: yup.string().required('Report Required'),
        value: yup.string().required('Report Required'),
    }).required('Report required'),
    parenthisys_control: yup.object().shape( {
        label: yup.string().required('Account type Required'),
        value: yup.string().required('Account type Required'),
    }).required('Account type required'),
    name: yup.string().required('Name required'),
    description: yup.string()
})

export const GLSearchFormValidate = yup.object().shape({
    month: yup.object().shape( {
        label: yup.string().required('Month Required'),
        value: yup.string().required('Month Required'),
    }).required('Month required'),
    year: yup.object().shape( {
        label: yup.string().required('Year Required'),
        value: yup.string().required('Year Required'),
    }).required('Year required'),
    
})

export const SalesExpenseValidate = yup.object().shape({
    sales: yup.array().of(
        yup.object().shape({
            checked: yup.boolean(),
            //id: yup.any(),
            parent_id: yup.number(),
            description: yup.string().nullable(),
            account_name: yup.string().nullable(),
            transaction_at: yup.string(),
            gross_sale: yup.number(),
            state_tax: yup.number(),
            // adjusted_bank_deposit: yup.number(),
            overshort: yup.number(),
            split : yup.array(),
            account_type: yup.string(),

            gross_sale_master_account_id: yup.object().when(['gross_sale', 'checked', 'split', 'account_type'], {
                // is: (grossSale: number, isChecked: boolean) => +grossSale && isChecked,
                is: (grossSale: number, isChecked: boolean, split: any[], account_type: string) => {
                    return +grossSale && isChecked  && split.length === 0 && account_type === 'Gross_Sale';
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            state_tax_master_account_id: yup.object().when(['state_tax', 'checked', 'split', 'account_type'], {
                is: (stateTax: number, isChecked: boolean, split: any[], account_type: string) => {
                    return +stateTax && isChecked && split.length === 0 && account_type === 'State_Tax';
                },
                //is: (stateTax: number, isChecked: boolean, account_type: string) => +stateTax && isChecked && account_type == 'State_Tax',
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            /*adjusted_bank_deposit_account_id: yup.object().when(['adjusted_bank_deposit', 'checked'], {
                is: (adjustedBankDeposit: number, isChecked: boolean) => +adjustedBankDeposit && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),*/
            overshort_master_account_id: yup.object().when(['overshort', 'checked', 'account_type'], {
                //is: (overshort: number, isChecked: boolean, account_type: string) => +overshort && isChecked && account_type == 'Overshort',
                is: (overshort: number, isChecked: boolean, account_type: string) => {
                    return +overshort && isChecked  && account_type === 'Overshort';
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
        })
    ),
})
export const BankExpenseValidate = yup.object().shape({
    banks: yup.array().of(
        yup.object().shape({
            checked: yup.boolean(),
            id: yup.number(),
            parent_id: yup.number(),
            description: yup.string().nullable(),
            account_name: yup.string().nullable(),
            transaction_at: yup.string(),
            deposit: yup.number(),
            split : yup.array(),
            // equity: yup.number(),
            // bank_transfer: yup.number(),
            // others: yup.number(),
            // adjusted_bank_deposit: yup.number(),
            deposit_debit_account_id: yup.object().when(['deposit', 'checked', 'split'], {
                is: (deposit: number, isChecked: boolean, split: any[]) => {
                    return +deposit && isChecked && split.length === 0;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            deposit_credit_account_id: yup.object().when(['deposit', 'checked', 'split'], {
                is: (deposit: number, isChecked: boolean, split: any[]) => {
                    return +deposit && isChecked && split.length === 0;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            /*equity_master_account_id: yup.object().when(['equity', 'checked'], {
                is: (equity: number, isChecked: boolean) => +equity && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            bank_transfer_master_account_id: yup.object().when(['bank_transfer', 'checked'], {
                is: (bankTransfer: number, isChecked: boolean) => +bankTransfer && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            others_master_account_id: yup.object().when(['others', 'checked'], {
                is: (others: number, isChecked: boolean) => +others && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            adjusted_bank_deposity_master_account_id: yup.object().when(['adjusted_bank_deposit', 'checked'], {
                is: (adjustedBankDeposit: number, isChecked: boolean) => +adjustedBankDeposit && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),*/
        })
    ),
})
export const BankRevenueValidate = yup.object().shape({
    revenues: yup.array().of(
        yup.object().shape({
            checked: yup.boolean(),
            id: yup.number(),
            parent_id: yup.number(),
            description: yup.string().nullable(),
            account_name: yup.string().nullable(),
            transaction_at: yup.string(),
            deposit: yup.number(),
            equity: yup.number(),
            bank_transfer: yup.number(),
            others: yup.number(),
            adjusted_bank_deposit: yup.number(),
            equity_split : yup.array(),
            bank_transfer_split : yup.array(),
            others_split : yup.array(),
            deposit_master_account_id: yup.object().when(['deposit', 'checked'], {
                is: (deposit: number, isChecked: boolean) => {
                    return +deposit && isChecked
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            equity_master_account_id: yup.object().when(['equity', 'checked','equity_split'], {
                //is: (equity: number, isChecked: boolean) => +equity && isChecked,
                is: (equity: number, isChecked: boolean, equity_split: any[]) => {
                    return +equity && isChecked && equity_split.length === 0;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            bank_transfer_master_account_id: yup.object().when(['bank_transfer', 'checked','bank_transfer_split'], {
                //is: (bankTransfer: number, isChecked: boolean) => +bankTransfer && isChecked,
                is: (bankTransfer: number, isChecked: boolean, bank_transfer_split: any[]) => {
                    return +bankTransfer && isChecked && bank_transfer_split.length === 0;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            others_master_account_id: yup.object().when(['others', 'checked','others_split'], {
                // is: (others: number, isChecked: boolean) => +others && isChecked,
                is: (others: number, isChecked: boolean, others_split: any[]) => {
                    return +others && isChecked && others_split.length === 0;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            /*adjusted_bank_deposity_master_account_id: yup.object().when(['adjusted_bank_deposit', 'checked'], {
                is: (adjustedBankDeposit: number, isChecked: boolean) => +adjustedBankDeposit && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),*/
        })
    ),
})
export const BankReconcileValidate = yup.object().shape({
    reconciles: yup.array().of(
        yup.object().shape({
            checked: yup.boolean(),
            id: yup.number()
        })
    ),
})
export const SettingsBudgetValidate = yup.object().shape({
    budget_based_on: yup.object().shape({
        label: yup.string().required('Field is required'),
        value: yup.string().required('Field is required'),
    }).typeError('Field is required').required('Field is required'),
    first_month_of_fiscal_year: yup.object().shape({
        label: yup.string().required('Field is required'),
        value: yup.string().required('Field is required'),
    }).typeError('Field is required').required('Field is required'),
    first_month_of_income_tax_year: yup.object().shape({
        label: yup.string().required('Field is required'),
        value: yup.string().required('Field is required'),
    }).typeError('Field is required').required('Field is required')
})

export const SalesValidate = yup.object().shape({
    gl_account: yup.object().shape({
        label: yup.string().required('Field is required'),
        value: yup.string().required('Field is required'),
    }).typeError('Field is required').required('Field is required'),
    
})

export const BudgetValidate = yup.object().shape({
    budgets: yup.array().of(
        yup.object().shape({
            master_chart_of_account_number: yup.string(),
            master_chart_of_account_name: yup.string(),
            master_chart_of_account_id: yup.number(),
            parent_id: yup.number(),
            month_0: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_1: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_2: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_3: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_4: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_5: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_6: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_7: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_8: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_9: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_10: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_11: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
        })
    ),
})

export const RevRexBankAccSyncGlValidate = yup.object().shape({
    banks: yup.array().of(
        yup.object().shape({
            bank_account_id: yup.object().shape({
                label: yup.string().required('Bank account is required'),
                value: yup.string().required('Bank account is required'),
            }).typeError('Bank account is required').required('Bank account is required'),
            gl_account: yup.object().shape({
                label: yup.string().required('GL is required'),
                value: yup.string().required('GL is required'),
            }).typeError('GL is required').required('GL is required'),
        })
    ).test('unique_gl', 'Same VP can not be assigned to multiple bank accounts', (values: any) => {
        const gl_acc: string[] = values
        .filter((value: any) => value['gl_account'] && value['gl_account']['value'])
        .map((value: any) => value['gl_account']['value']);
        if ( values.length == gl_acc.length ) {
            let findDuplicates = gl_acc.filter((item: string, index: number) => gl_acc.indexOf(item) !== index)
            return findDuplicates.length ? false : true;
        } else {
            return true;
        }
    })
})

export const RevRexSaleAccSyncGlValidate = yup.object().shape({
    sales: yup.array().of(
        yup.object().shape({
            sale_account_id: yup.object().shape({
                label: yup.string().required('Sale account is required'),
                value: yup.string().required('Sale account is required'),
            }).typeError('Sale account is required').required('Sale account is required'),
            gl_account: yup.object().shape({
                label: yup.string().required('GL is required'),
                value: yup.string().required('GL is required'),
            }).typeError('GL is required').required('GL is required'),
        })
    ).test('unique_gl', 'Same VP can not be assigned to multiple sale accounts', (values: any) => {
        const gl_acc: string[] = values
        .filter((value: any) => value['gl_account'] && value['gl_account']['value'])
        .map((value: any) => value['gl_account']['value']);
        
        if ( values.length == gl_acc.length ) {
            let findDuplicates = gl_acc.filter((item: string, index: number) => gl_acc.indexOf(item) !== index)
            return findDuplicates.length ? false : true;
        } else {
            return true;
        }
    })
})

export const BankReconcileFormValidate = yup.object().shape({
    beginningBalance: yup.string()
    .matches(/^\d*(\.\d{1,2})?$/, 'Must be a number with up to two decimal places')
    .required('Beginning is required'),
    endingBalance: yup.string()
    .matches(/^\d*(\.\d{1,2})?$/, 'Must be a number with up to two decimal places')
    .required('Ending is required'),
})

export const CreateTransactionAdjustmentValidate = yup.object().shape({
    description: yup.string()
    .nullable()
    .strict()
    .typeError('Description must be a string'),
    transaction_at:  yup.string().required('Date is required').typeError('Enter a  valid date'),
    adjustment_debit: yup.number().required('Debit amount is required').typeError('Enter a valid number'),
    adjustment_credit: yup.number().required('Credit amount is required').typeError('Enter a valid number'),
    adjustment_master_credit_account_id: yup.object().shape( {
            label: yup.string().required('GL is required'),
            value: yup.string().required('GL is required'),
    }).required('GL is required'),
    adjustment_master_debit_account_id: yup.object().shape( {
        label: yup.string().required('GL is required'),
        value: yup.string().required('GL is required'),
}).required('GL is required'),
    
}).test('amounts-match', function (value) {
    const { adjustment_debit, adjustment_credit } = value;

    if (adjustment_debit !== adjustment_credit) {
        // Set the error message for both fields specifically
        return this.createError({
            path: 'adjustment_debit',
            message: 'Amounts must match',
        });
    }

    return true;
});