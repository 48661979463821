
import { call, put } from "redux-saga/effects";
import { updateUser, authenticateUser,updateAccessToken } from "../store/user";
import { API_URLS } from "../resources/constants";
import { formQueryString } from '../resources/functions'
import {CallApi} from './callApi'

export function* balanceSheet(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.REPORT.BALANCE_SHEET + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}
export function* reportDownload(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.REPORT.REPORT_DOWNLOAD_BS + '?' + formQueryString(payload)
    let result = yield call(CallApi.DOWNLOAD, url,{},true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}
export function* reportDownloadBT(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.REPORT.REPORT_DOWNLOAD_BT + '?' + formQueryString(payload)
    let result = yield call(CallApi.DOWNLOAD, url,{},true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}
export function* profitLoss(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.REPORT.PROFIT_LOSSS + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* cashFlow(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.REPORT.STATEMENT_OF_CASH_FLOW + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* dashboardRatio(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.REPORT.DASHBOARD_RATIO + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}