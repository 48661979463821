import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useBudgetApi() {

    const callApi = useApiCall();

    const fetchBudget = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.BUDGET.FETCH_DETAILS, data, onSuccess, onError);
    }

    const postBudget = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.BUDGET.ADD, data, onSuccess, onError);
    }

    return {
        fetchBudget,
        postBudget,
    }
}