
import { call, put } from "redux-saga/effects";
import { updateUser, authenticateUser,updateAccessToken } from "../store/user";
import { API_URLS } from "../resources/constants";
import { formQueryString } from '../resources/functions'
import {CallApi} from './callApi'

export function* list(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.CHART_ACCOUNT.LIST + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* accountDetails(action: any): any {
  try {
    const payload = action.payload;
    const {id, ...queryString} = payload;
    
    const url = API_URLS.CHART_ACCOUNT.DETAILS + `/${id}` + '?' + formQueryString(queryString);
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* create(action: any): any {
    try {
      const payload = action.payload;
      const {branch_id, ...data } = payload;
     
      let result = yield call(CallApi.POST, API_URLS.CHART_ACCOUNT.CREATE + `?branch_id=${branch_id}`, data, true);
      action && action.callbackSuccess(result);
      
    } catch (e) {
    
      action && action.callbackError(e)
    }
}

export function* update(action: any): any {
    try {
      const payload = action.payload;
      const {branch_id, ...data } = payload;
      let result = yield call(CallApi.PUT, API_URLS.CHART_ACCOUNT.UPDATE + `/${data.id}?branch_id=${branch_id}`,data, true);
      action && action.callbackSuccess(result);
    } catch (e) {
      action && action.callbackError(e)
    }
}

export function* availableAccounts(action: any): any {
  try {
    const payload = action.payload;
    
    const url = API_URLS.CHART_ACCOUNT.AVAILABLE_ACCOUNT  + '?' + formQueryString(payload);
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* gl_account_list(action: any): any {
  try {
    const payload = action.payload;
    //console.log(payload);
    const url = API_URLS.CHART_ACCOUNT.GL_ACCOUNT_LIST + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* list_tree(action: any): any {
  try {
    const payload = action.payload;
    //console.log(payload);
    const url = API_URLS.CHART_ACCOUNT.LIST_TREE + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* lebel(action: any): any {
  try {
    const payload = action.payload;
    const {account, ...queryString} = payload;
    //console.log(payload);
    const url = API_URLS.CHART_ACCOUNT.ACCOUNT_LEBEL + `/${payload.account}?` +  formQueryString(queryString)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* bankAccountList(action: any): any {
  try {
    const payload = action.payload;
    //console.log(payload);
    const url = API_URLS.CHART_ACCOUNT.BANK_SALE_LIST + `?` +  formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* bankAccountUpdate(action: any): any {
  try {
    const { formData, queryString } = action.payload;
    //console.log(payload);
    const url = API_URLS.CHART_ACCOUNT.BANK_SALE_UPDATE + `?` +  formQueryString(queryString)
    let result = yield call(CallApi.PUT, url,formData,true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}