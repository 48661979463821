import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useChartAccountApi() {

    const callApi = useApiCall();

    const getList = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CHART_ACCOUNT.LIST, data, onSuccess, onError);
    }
    const getDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CHART_ACCOUNT.DETAILS, data, onSuccess, onError);
    }
    const createChart = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CHART_ACCOUNT.CREATE, data, onSuccess, onError);
    }
    const updateChart = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CHART_ACCOUNT.UPDATE, data, onSuccess, onError);
    }
    const chartAvailableAccounts = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CHART_ACCOUNT.AVAILABLE_ACCOUNT, data, onSuccess, onError);
    }
    const glAccountsList = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CHART_ACCOUNT.GL_ACCOUNT_LIST, data, onSuccess, onError);
    }
    const getListTree = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CHART_ACCOUNT.LIST_TREE, data, onSuccess, onError);
    }
    const getAccountLebel = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CHART_ACCOUNT.ACCOUNT_LEBEL, data, onSuccess, onError);
    }
    const getBankSaleList = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CHART_ACCOUNT.BANK_SALE_LIST, data, onSuccess, onError);
    }
    const updateBankSale = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CHART_ACCOUNT.BANK_SALE_UPDATE, data, onSuccess, onError);
    }

    return {
        getList,
        getDetails,
        createChart,
        updateChart,
        chartAvailableAccounts,
        glAccountsList,
        getListTree,
        getAccountLebel,
        getBankSaleList,
        updateBankSale
    }    
}