import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import toast from 'react-hot-toast';
import moment from 'moment';
import { currencyFormat } from '../../../resources/helper';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';

interface SplitData {
    rowID: number;
    amount: number;
    master_chart_of_account_id: string;
}

interface SplitModalProps {
    rowID: number;
    show: boolean;
    onHide: () => void;
    onSave: (rowID: number, indexNo: number, splits: SplitData[]) => void;
    originalAmount: number;
    glCreditAccount: any[];
    transactionAt: string;
    description: string;
    splitData: SplitData[];
    indexNo: number;
    filterAllAccountList: (inputValue: string) => Promise<any[]>;
}

const OtherSplitModal: React.FC<SplitModalProps> = ({
    rowID, show, onHide, onSave, originalAmount, glCreditAccount, transactionAt, description, splitData, indexNo, filterAllAccountList
}) => {
    const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            splits: [{ amount: '', creditAccount: '' }]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'splits'
    });

    const [remainingAmount, setRemainingAmount] = useState(originalAmount);

    const amountInputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const splits = useWatch({ control, name: 'splits' });

    useEffect(() => {
        const totalAmount = splits.reduce((sum, split) => {
            const amount = split.amount ? parseFloat(split.amount.replace(/,/g, '')) : 0;
            return sum + amount;
        }, 0);
        setRemainingAmount(parseFloat((originalAmount - totalAmount).toFixed(2)));
    }, [splits, originalAmount]);

    useEffect(() => {
        if (show) {
            reset({ splits: [] });

            const existingSplits = splitData.filter(data => data.rowID === rowID);
            if (existingSplits.length > 0) {
                existingSplits.forEach(data => {
                    append({
                        amount: data.amount.toString(),
                        creditAccount: data.master_chart_of_account_id || ''
                    });
                });
            } else {
                append({ amount: '', creditAccount: '' });
            }

            setTimeout(() => {
                if (amountInputRefs.current[0]) {
                    amountInputRefs.current[0]?.focus();
                }
            }, 100);
        }
    }, [show, splitData, reset, append, rowID]);

    const onSubmit = (data: any) => {
        const totalAmount = data.splits.reduce((sum: number, split: any) => {
            const amount = split.amount ? parseFloat(split.amount.replace(/,/g, '')) : 0;
            return sum + amount;
        }, 0);
        if (parseFloat(totalAmount.toFixed(2)) === parseFloat(originalAmount.toFixed(2))) {
            const formattedSplits = data.splits.map((split: any) => ({
                id: rowID,
                amount: parseFloat(split.amount.replace(/,/g, '')),
                master_chart_of_account_id: split.creditAccount,
                //deposit_credit_account_id: creditAccountId,
                rowID: rowID,
            }));
            onSave(rowID, indexNo, formattedSplits);
            reset();
            onHide();
        } else {
            toast.error("The amount must equal the original balance");
        }
    };

    const handleClose = () => {
        reset();
        onHide();
    };
    const handleAppend = () => {
        append({ amount: '', creditAccount: '' });
        setTimeout(() => {
            // Check if the current reference exists before trying to focus on it
            const lastAmountInput = amountInputRefs.current[fields.length];
            if (lastAmountInput) {
                lastAmountInput.focus();
            }
        }, 100);
    };


    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Others Split Amount</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td><b>Date:</b></td>
                                <td>{moment(transactionAt).format('MM-DD-YYYY h:mm:ss')}</td>
                            </tr>
                            <tr>
                                <td><b>Description:</b></td>
                                <td className="text-start text-truncate" style={{ maxWidth: '150px' }} title={description || 'NA'}>
                                    {description || 'NA'}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Amount:</b></td>
                                <td>{currencyFormat(originalAmount.toFixed(2))}</td>
                            </tr>
                            {/* <tr>
                                <td><b>Credit GL Account:</b></td>
                                <td>{creditAccountName}</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th style={{ width: '200px' }}>Amount</th>
                                <th>Credit GL Account</th>
                                <th className="text-center" style={{ width: '100px' }}>Add Transaction</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields.map((item, index) => (
                                <tr key={item.id}>
                                    <td>
                                        <Controller
                                            name={`splits.${index}.amount`}
                                            control={control}
                                            rules={{ required: 'Amount is required', min: { value: 0, message: 'Amount must be positive' } }}
                                            render={({ field }) => (
                                                <InputGroup hasValidation className="input-group-custom">
                                                    <InputGroup.Text>$</InputGroup.Text>
                                                    <CurrencyInput
                                                        className={`form-control ${errors.splits?.[index]?.amount ? 'is-invalid' : ''}`}
                                                        placeholder="0.00"
                                                        value={field.value}
                                                        onValueChange={(value) => setValue(`splits.${index}.amount`, value || '')}
                                                        allowDecimals
                                                        decimalsLimit={2}
                                                        decimalSeparator="."
                                                        groupSeparator=","
                                                        disableAbbreviations
                                                        ref={(el) => amountInputRefs.current[index] = el}
                                                    //ref={index === 0 ? amountInputRef : null} // Set ref to first amount input field
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.splits?.[index]?.amount?.message}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            )}
                                        />
                                    </td>

                                    <td>
                                        <Controller
                                            name={`splits.${index}.creditAccount`}
                                            control={control}
                                            rules={{ required: 'GL account is required' }}
                                            render={({ field }) => (
                                                <AsyncSelect
                                                    {...field}
                                                    loadOptions={filterAllAccountList}
                                                    defaultOptions={glCreditAccount}
                                                    isSearchable
                                                    placeholder="Please select Credit GL acc"
                                                    className={errors.splits?.[index]?.creditAccount ? 'is-invalid' : ''}
                                                />
                                            )}
                                        />
                                        <div className="invalid-feedback d-block">
                                            {errors.splits?.[index]?.creditAccount?.message}
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        {fields.length > 1 && (
                                            <Button variant="danger" onClick={() => remove(index)} className="me-2">
                                                <span role="img" aria-label="Remove">-</span>
                                            </Button>
                                        )}
                                        <Button variant="secondary" onClick={handleAppend}>
                                            <span role="img" aria-label="Add">+</span>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="mt-3">
                        <b>Transaction ATM:</b> {currencyFormat(originalAmount.toFixed(2))}<br />
                        <b>Remaining Split Total:</b> <span style={{ color: remainingAmount < 0 ? 'red' : 'black' }}>{currencyFormat(remainingAmount.toFixed(2))}</span>
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                        <Button type="submit" variant="primary" className="me-2">Split & Submit</Button>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default OtherSplitModal;
