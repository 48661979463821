
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import SAlert from '../../components/SAlert';
import { getUser } from '../../store/user/selectors'
//import { AddBudgetIFormI, BalanceFormI } from '../../resources/form-props';
import { getAccountSettings, getBudgetSettings } from '../../store/settings/selectors'
import { useBudgetApi } from '../../resources/hooks/api/useBudgetApiHook';
import { useSettingsApi } from '../../resources/hooks/api/useSettingsApiHook';
import Select from 'react-select';
import toast from 'react-hot-toast';
//import { useUserDataApi } from '../../resources/hooks/api/userDataApiHook'
import { MONTHS } from '../../resources/constants';
import { nextYears, nextInclomeTaxYears, calculateMaxHeightContent, capitalizeFirstCharacter } from '../../resources/functions';
import { currencyFormat } from '../../resources/helper';
import { BudgetValidate } from '../../resources/form-validator';

import {getBranch} from '../../store/user/selectors';
import CurrencyInput from 'react-currency-input-field';
import InputGroup from 'react-bootstrap/InputGroup';
import { Console } from 'console';

interface Props {

}

interface BudgetMonthI {
    amount: any,
    is_editable: boolean
}
interface BudgetField {
    master_chart_of_account_number: string,
    master_chart_of_account_name: string,
    master_chart_of_account_id: number,
    parent_id: number,
    [key: `month_${number}`]: BudgetMonthI;
    // [key: string]: BudgetMonthI // To handle dynamic month properties
}

interface AddBudgetIFormI {
    budgets: BudgetField[];
}

const Budget: React.FC<Props> = () => {

    const budgetSettings = useSelector(getBudgetSettings)
    const { fetchBudget, postBudget } = useBudgetApi();
    const { fetchAccountSettingsData } = useSettingsApi();

    const [glBudgetList, setGlBudgetList] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useState<any>({
        year: null
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRecordsExists, setIsRecordsExists] = useState<boolean>(false);
    const [searchError, setSearchError] = useState<string>('');
    const [tableHeaders, setTableHeaders] = useState<any[]>([]);
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0);
    const [showBudgetAlert, setShowBudgetAlert] = useState<any>({
        timestamp: new Date().getTime(),
        show: false
    });

    const branchData = useSelector(getBranch)

    // const { register, reset, trigger, control, handleSubmit, formState: { errors } } = useForm<AddBudgetIFormI>({
    //     defaultValues: {
    //         budgets: []
    //     },
    //     resolver: yupResolver(BudgetValidate)
    // });
    const { register, reset, trigger, control, handleSubmit, setValue, formState: { errors } } = useForm<AddBudgetIFormI>({
        defaultValues: {
            budgets: []
        },
        resolver: yupResolver(BudgetValidate)
    });
    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "budgets"
    });


    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(78));
    })
    useEffect(() => {
        getSettingsData()
    }, [])
    useEffect(() => {
        if (glBudgetList.length) {
            setTableHeaders(glBudgetList[0]['details'])
        }
    }, [glBudgetList])
    useEffect(() => {
        formRemove();
        glBudgetList.map((record: any, index: number) => {
            var monthAmounts = {
                month_0: {
                    amount: 0,
                    is_editable: false
                },
                month_1: {
                    amount: 0,
                    is_editable: false
                },
                month_2: {
                    amount: 0,
                    is_editable: false
                },
                month_3: {
                    amount: 0,
                    is_editable: false
                },
                month_4: {
                    amount: 0,
                    is_editable: false
                },
                month_5: {
                    amount: 0,
                    is_editable: false
                },
                month_6: {
                    amount: 0,
                    is_editable: false
                },
                month_7: {
                    amount: 0,
                    is_editable: false
                },
                month_8: {
                    amount: 0,
                    is_editable: false
                },
                month_9: {
                    amount: 0,
                    is_editable: false
                },
                month_10: {
                    amount: 0,
                    is_editable: false
                },
                month_11: {
                    amount: 0,
                    is_editable: false
                },
            }
            record['details'].map((eachMonth: any, monthIndex: number) => {
                monthAmounts = {
                    ...monthAmounts,
                    [`month_${monthIndex}`]: {
                        amount: eachMonth['amount'] || '',
                        is_editable: !!eachMonth['is_editable']
                    }
                }
            })
            formAppend({
                master_chart_of_account_number: record['account_chart']['account'],
                master_chart_of_account_name: record['account_chart']['name'],
                master_chart_of_account_id: record['account_chart']['id'],
                parent_id: record['account_chart']['parent_id'],
                ...monthAmounts
            })
        })
        // setFocus(`budgets.0.month_0`)
    }, [glBudgetList])

    const getSettingsData = () => {
        fetchAccountSettingsData({ branch_id: +branchData['id'] }, (msg: string, resp: any) => {

        }, (msg: string, resp: any) => {

        })
    }

    const fetchBudgetDetails = (alterBudgetAlert: boolean) => {

        if (searchParams && searchParams['year']) {
            const params = {
                year: +searchParams['year']['value'],
                branch_id: +branchData['id']
            }
            setIsLoading(true);
            fetchBudget(params, (message: string, resp: any) => {
                setIsLoading(false);
                setIsRecordsExists(resp.data.data.budget_exists);
                setGlBudgetList(resp.data.data.data);
                if (alterBudgetAlert) {
                    setShowBudgetAlert({
                        timestamp: new Date().getTime(),
                        show: resp.data.data.budget_exists
                    })
                }
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message)
            })
        } else {
            setSearchError('Please select year')
        }
    }

    const _handleChangeSearchParam = (e: any) => {
        setSearchError('')
        setSearchParams({
            year: e
        })
    }

    const processData = (data: any) => {
        
        const formData = data['budgets'].reduce((recordAcc: any[], record: any) => {
            const result = tableHeaders.reduce((acc: any[], c: any, currentIndex: number) => {
                if ( record[`month_${currentIndex}`]['is_editable'] ) {
                    acc.push({
                        "master_chart_of_account_id": record['master_chart_of_account_id'],
                        parent_id: record['parent_id'],
                        "total_amount": (record[`month_${currentIndex}`]['amount'] || '0').replace(/,/g, ''),
                        "month": c['month_count'],
                        "year": c['year']
                    });
                }               
                return acc;
            }, [])
            recordAcc.push.apply(recordAcc, result);
            return recordAcc;
        }, [])
        // console.log('data: ', formData)
        const params = {
            year: +searchParams['year']['value'],
            branch_id: +branchData['id'],
            formData
        }
        setIsLoading(true);
        postBudget(params, (message: string, resp: any) => {
            setIsLoading(false);
            fetchBudgetDetails(false);
            toast.success(message)
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message)
        })
    }

    const closeBudgetModal = () => {
        setShowBudgetAlert({
            timestamp: new Date().getTime(),
            show: false
        })
    }


    // Initialize budgetValues as an array of arrays
    const [budgetValues, setBudgetValues] = useState<string[][]>([]);

    // Function to handle changes in budget values
    const handleBudgetChange = (rowIndex: number, colIndex: number, value: string) => {
        // Make a copy of budgetValues to avoid mutating state directly
        const updatedBudgetValues = [...budgetValues];

        // Check if the row exists, if not, initialize it as an empty array
        if (!updatedBudgetValues[rowIndex]) {
            updatedBudgetValues[rowIndex] = [];
        }

        // Update the value in the array
        updatedBudgetValues[rowIndex][colIndex] = currencyFormat(parseFloat(value || '0').toFixed(2))

        // Update the state with the new array
        setBudgetValues(updatedBudgetValues);
    };
    
    
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, rowIndex: number, colIndex: number) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextInput: HTMLInputElement | null = document.querySelector(`#budgets-${rowIndex}-${colIndex + 1}`) || 
                document.querySelector(`#budgets-${rowIndex + 1}-${0}`);
            nextInput?.focus();
        }
    }
    const calculateRowTotal = (row: BudgetField) => {
        
        return Object.keys(row).reduce((total: any, key: any) => {
            if (key.startsWith('month_')) {
                const amount = row[key]?.amount;
                if (typeof amount === 'string') {
                    return total + parseFloat(amount.replace(/,/g, '') || '0');
                } else if (typeof amount === 'number') {
                    return total + amount;
                }
            }
            return total;
        }, 0).toFixed(2);
    };

    const formatNumberWithCommas = (number: string) => {
        return currencyFormat(parseFloat(number).toFixed(2));
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Budget</h1>
                        </div>
                    </div>
                </div>
                <div className="filter-box-top">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="filter-wrap">
                                <div className="filter-box">
                                    <Select
                                        placeholder={budgetSettings?.budget_based_on?.value == 1 ? 'Select Fiscal Year' : 'Select Income Tax Year'}
                                        onChange={(e: any) => _handleChangeSearchParam(e)}
                                        options={budgetSettings?.budget_based_on?.value == 1 ? nextYears(5) : nextInclomeTaxYears(10)}
                                        className={'zindex-10'}
                                    />
                                    {
                                        searchError && <span className='text-danger'>{searchError}</span> || <></>
                                    }
                                </div>
                                <div className="filter-box">
                                    <button type="button" onClick={() => fetchBudgetDetails(true)} className="generate-btn">Generate</button>
                                </div>
                                <div className="filter-btn">
                                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="executive-dashboard">
                    <Form>
                        <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                            {
                                fields.length > 0 && (
                                    <table className="table table-default">
                                        <thead>
                                            <tr className="align-middle">
                                                <th className="text-center">Account Name</th>
                                                {
                                                    tableHeaders.map((calendar: any) => <th className="text-center">{capitalizeFirstCharacter(calendar?.month)}{' '}{calendar?.year}</th>)
                                                }
                                                <th className="text-center">Total</th>
                                            </tr>
                                            
                                        </thead>
                                        <tbody>
                                            {fields.map((data, rowIndex) => (
                                                <tr className='align-middle h-60' key={rowIndex}>
                                                    <td>
                                                        <span>{fields[rowIndex]['master_chart_of_account_number']}{' '}{fields[rowIndex]['master_chart_of_account_name']}</span>
                                                    </td>
                                                    {tableHeaders.map((calendar, colIndex: number) => {
                                                        return (
                                                            <>
                                                            <td key={colIndex} className='login-input-container text-center'>
                                                                {!fields[rowIndex][`month_${colIndex}`]['is_editable']
                                                                    ? <span>{currencyFormat(parseFloat(fields[rowIndex][`month_${colIndex}`]['amount'] || '0').toFixed(2))}</span>
                                                                    : <div>
                                                                         {/* <input
                                                                            type='text'
                                                                            className='form-control login-input'
                                                                            id={`budgets-${rowIndex}-${colIndex}`}
                                                                            {...register(`budgets.${rowIndex}.month_${colIndex}.amount` as const)}
                                                                            title={fields[rowIndex]?.[`month_${colIndex}`]?.amount ?? '0'}
                                                                            onFocus={(e) => {
                                                                                if (e.target.value === '0') {
                                                                                    e.target.value = '';
                                                                                }
                                                                            }}
                                                                            onBlur={(e) => handleBlur(e, rowIndex, colIndex)}
                                                                            onChange={(e) => handleBudgetChange(rowIndex, colIndex, e.target.value)}
                                                                            onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
                                                                            autoFocus={rowIndex === 0 && colIndex === 0}
                                                                        />
                                                                        {errors?.budgets?.[rowIndex]?.[`month_${colIndex}`]?.amount && (
                                                                            <Form.Text className="text-danger d-flex">
                                                                                <strong>
                                                                                    {errors.budgets[rowIndex]?.[`month_${colIndex}`]?.amount?.message as ReactNode || ''}
                                                                                </strong>
                                                                            </Form.Text>
                                                                        )} */}
                                                                        <Controller
                                                                        name={`budgets.${rowIndex}.month_${colIndex}.amount`}
                                                                     control={control}
                                                                     render={({ field }) => (
                                                                        <InputGroup hasValidation>
                                                                         <InputGroup.Text>$</InputGroup.Text>
                                                                        <CurrencyInput
                                                                        className='form-control login-input'
                                                                            id={`budgets-${rowIndex}-${colIndex}`}
                                                                            {...register(`budgets.${rowIndex}.month_${colIndex}.amount` as const)}
                                                                            title={budgetValues[rowIndex]?.[colIndex] ?? '$ 0.00'}
                                                                            placeholder="0.00"
                                                                            decimalsLimit={2}
                                                                            //value={field.value}
                                                                            onValueChange={(value) => {
                                                                                const parsedValue =  (value || '').replace(/,/g, ''); // Remove commas
                                                                                field.onChange(parsedValue || '0');
                                                                                handleBudgetChange(rowIndex, colIndex, value || '0');
                                                                            }}
                                                                            
                                                                            //onChange={(e) => handleBudgetChange(rowIndex, colIndex, e.target.value)}
                                                                            onKeyDown={(e) => {
                                                                                handleKeyDown(e, rowIndex, colIndex);
                                                        
                                                                                const allowedKeys = [
                                                                                    'Backspace',
                                                                                    'Tab',
                                                                                    'ArrowLeft',
                                                                                    'ArrowRight',
                                                                                    'ArrowUp',
                                                                                    'ArrowDown',
                                                                                    'Delete',
                                                                                    'Enter',
                                                                                    'Escape',
                                                                                ];
                                                        
                                                                                // Allow control keys
                                                                                if (allowedKeys.includes(e.key)) {
                                                                                    return;
                                                                                }
                                                        
                                                                                // Allow only numbers, comma, and dot
                                                                                if (!/^[0-9.]$/.test(e.key)) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                        
                                                                        />
                                                                        
                                                                        </InputGroup>
                                                                        )}
                                                                        />
    
                                                                        {errors?.budgets?.[rowIndex]?.[`month_${colIndex}`]?.amount && (
                                                                            <Form.Text className="text-danger d-flex">
                                                                                <strong>
                                                                                    {errors.budgets[rowIndex]?.[`month_${colIndex}`]?.amount?.message as ReactNode || ''}
                                                                                </strong>
                                                                            </Form.Text>
                                                                        )}
                                                                        
                                                                    </div>
                                                                }
                                                            </td>
                                                            
                                                            </>
                                                        );
                                                    })}
                                                    <td className="text-center">
                                                                {formatNumberWithCommas(calculateRowTotal(data))}
                                                            </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )
                            }

                        </div>
                        {
                            !isRecordsExists && fields.length > 0 && (
                                <div className="col-md-12 mt-3 text-center">
                                    <button type="button" className="btn btn-secondary" onClick={handleSubmit(processData)}>Save Budget</button>
                                </div> || <></>
                            )
                        }
                    </Form>
                </div>
            </div>

            <SAlert
                msg={'As the budget for the selected year is already defined, you can only view those records.'}
                show={showBudgetAlert['show']}
                title={'You have saved the budget already'}
                onConfirm={closeBudgetModal}
                confirmBtnText='Okay'
                onCancel={() => { }}
            />
        </div>

    )
}

export default Budget;