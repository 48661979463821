import { MONTHS } from "./constants";

export const formQueryString = ( object: any ): string => {
    return Object.keys(object).map((key: string) => `${key}=${object[key]}`).join('&')
}

export const lastYears = (n: number) => {
    const currentYear = new Date().getFullYear();
    const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1}, (_, i) => ({
        label: ''+(start + (i * step)),
        value: start + (i * step)
    }));
    return range(currentYear, currentYear - n, -1)
}

export const lastInclomeTaxYears = (n: number) => {
    const currentYear = new Date().getFullYear();
    const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1}, (_, i) => ({
        label: ''+(start + (i * step))+' - ' +(start + (i * step) + 1),
        value: start + (i * step)
    }));
    return range(currentYear, currentYear - n, -1)
}

export const nextYears = (n: number) => {
    const currentYear = new Date().getFullYear();
    const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1}, (_, i) => ({
        label: ''+(start + (i * step)),
        value: start + (i * step)
    }));
    return range(currentYear, currentYear + n, 1)
}

export const nextInclomeTaxYears = (n: number) => {
    const currentYear = new Date().getFullYear();
    const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1}, (_, i) => ({
        label: ''+(start + (i * step))+' - ' +(start + (i * step) + 1),
        value: start + (i * step)
    }));
    return range(currentYear, currentYear + n, 1)
}

// headerHeight:  114  pageTitleHeight:  74  pageFilterHeight:  45  pageFooterHeight:  52  windowHeight:  687

export const calculateMaxHeightContent = (extraMarginPadding: number = 0): number => {
    const headerHeight = document.getElementById('ux_page_header')?.offsetHeight || 0;
    const pageTitleHeight = document.getElementById('ux_page_title')?.offsetHeight || 0;
    const pageFilterHeight = document.getElementById('ux_page_filter')?.offsetHeight || 0;
    const pageFooterHeight = document.getElementById('ux_page_footer')?.offsetHeight || 0;

    const windowHeight = window.innerHeight;

    return windowHeight - ( headerHeight + pageTitleHeight + pageFooterHeight + pageFilterHeight + extraMarginPadding);

}

export const transformMonths = (): any[] => {
    return MONTHS.map((record: any) => ({ value: +record['value'], label: record['label'] }))
}

export const capitalizeFirstCharacter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.substr(1)
}

export const fileDownloadBlob = ( blob: Blob, fileName: string ) => {
    // Create a blob URL
    const blobUrl = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = blobUrl;

    // Set the download attribute with a filename
    link.download = fileName;

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Optionally, revoke the object URL to release memory
    window.URL.revokeObjectURL(blobUrl);
}